<app-site-header></app-site-header>
<router-outlet></router-outlet>
<!--routeroutlet-->


<section class="footer-section" *ngIf="router.url !== '/login'">
  <div class="container">
    <div class="row" style="padding-top: 20px;padding-bottom: 25px;font-size: 15px">
      <div class="col-12 text-white">
        <h5 class="m-0">IrvineTek</h5>
      </div>
      <div class="col-12 text-white footer-links">
        <a  routerLink="/services">Services</a>
        <a  routerLink="/portfolio">Portfolio</a>
        <a  routerLink="/insights">Insights</a>
        <a  routerLink="/estimate">Free Estimate</a>
        <a  routerLink="/about-us">About Us</a>
      </div>
      <div class="col-12 text-white" style="margin-top: 13px">
        <a routerLink="/corporate-social-responsibility-policy">Corporate Social Responsibility </a>|
        <a routerLink="/disclaimer">Disclaimer</a>
      </div>
      <div class="col-12 ">
        <p class="my-auto text-white">© 2021 All rights reserved | This site is maintained by <a href="https://irvinetek.com/" target="_blank">IrvineTek</a></p>
      </div>

    </div>
  </div>
</section>
