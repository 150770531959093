import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css']
})
export class SiteHeaderComponent implements OnInit {
  pageName = ''
  constructor(public router: Router) {
    // this.pageName = router.url;
    // console.log('pageName', this.pageName);
  }

  ngOnInit(): void {
    // $('.footer-section').css('display', 'initial');
    // $('.headerMain').attr("style", "display: block !important");
  }
  openNav(): void {
    console.log('in');
    // $('#mySidenav').style.width = '250px';
    document.getElementById('mySidenav').style.width = '250px';
  }
  closeNav(): void {
    // $('#mySidenav').style.width = '0';
    document.getElementById('mySidenav').style.width = '0';
  }

}
