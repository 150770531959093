import { Component } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'irvinetek-website';
  pageName = '';
  constructor(public router: Router) {
    // this.pageName = router.url;
    // router.events.filter(event => event instanceof NavigationEnd)
    //   .subscribe(event =>
    //   {
    //     this.pageName = event.url;
    //     console.log(event);
    //   });
  }
    // console.log('pageName', this.pageName);
  }
