<!-- Navbar -->
<div *ngIf="router.url !== '/login'">
<nav class="navbar headerMain navbar-expand-lg navbar-expand-md navbar-light bg-transparent d-none d-sm-none d-md-flex" style="padding-left: 42px;padding-right: 42px">
  <a class="navbar-brand brand-text" routerLink="" [ngClass]="router.url === '/'? 'active-link': ''">IrvineTek.</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav mr-auto">
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/" fragment="home">Profile</a>-->
<!--      </li>-->
      <li class="nav-item">
        <a class="nav-link" routerLink="/services" routerLinkActive="active-link">Services</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/portfolio" routerLinkActive="active-link">Portfolio</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/insights" routerLinkActive="active-link">Insights</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/estimate" routerLinkActive="active-link">Free Estimate</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/about-us" routerLinkActive="active-link">About Us</a>
      </li>
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/" fragment="contactUs">Contact Us</a>-->
<!--      </li>-->

    </ul>
  </div>
  <a class="" routerLink="/contact-us" style="color: #ffffff;margin-right: 20px"><i class="fa fa-envelope"></i></a>
  <a class="" routerLink="/login" style="color: #ffffff"><i class="fa fa-user"></i></a>
</nav>
<!-- Sidebar Section-->
<section class="d-block d-sm-block d-md-none">

  <div id="mySidenav" class="sidenav">
    <a class="closebtn" (click)="closeNav()" >&times;</a>
    <a (click)="closeNav()" routerLink="/" [ngClass]="router.url === '/'? 'active-link': ''">Home</a>
    <a  (click)="closeNav()" routerLink="/services" routerLinkActive="active-link">Services</a>
    <a (click)="closeNav()" routerLink="/portfolio" routerLinkActive="active-link">Portfolio</a>
    <a  (click)="closeNav()" routerLink="/insights" routerLinkActive="active-link">Insights</a>
    <a  (click)="closeNav()" routerLink="/estimate" routerLinkActive="active-link">Free Estimate</a>
    <a  (click)="closeNav()" routerLink="/about-us" routerLinkActive="active-link">About Us</a>
    <a  (click)="closeNav()" routerLink="/contact-us" routerLinkActive="active-link">Contact Us</a>
    <a  (click)="closeNav()" routerLink="/login" routerLinkActive="active-link">Login</a>
<!--    <a (click)="closeNav()" routerLink="/" fragment="contactUs">Contact Us</a>-->
  </div>

  <div class="row mx-auto" style="background-color: #003c77;margin: 0px;padding-top: 5px;">
    <div class="col-xs-12 text-center">
      <span style="font-size:30px;cursor:pointer;color: #ffffff;margin-left: 10px;float: left;margin-top: 3px;" (click)="openNav()">&#9776;</span>

      <a class="navbar-brand"  routerLink="/" style="left: 35%;position: absolute">
        <p style="color: white;text-align: center;font-size: 25px;"><b>IrvineTek.</b></p></a>
    </div>
  </div>
</section>
</div>
